<template>
  <div>
    <DetailItemEditCard item-name="team" :promises.sync="detailItemEditCardPromises" title="Teamlid toevoegen"
      :detailViewRouteLocation="{ name: RouteNames.TEAM_DETAIL, params: { teamId: teamId } }"
      confirmationMessage="Teamlid succesvol toegevoegd" update-store-action="teamsModule/putNewMembersInTeam"
      :update-store-action-payload="{ teamId: teamId, newMembers: getFormResultSet }"
      :success-callback="usersAddedSuccessCallback">
      <template v-slot:form-fields>
        <v-row>
          <v-col cols="12" md="6">
            <AutoCompleteWithSelectAll v-model="selectedMembers" :items="members" :promises.sync="autoCompletePromises"
              item-text="fullName" item-value="userId" noTranslate/>
          </v-col>
        </v-row>
      </template>
    </DetailItemEditCard>
  </div>
</template>

<script>
import DetailItemEditCard from "@/components/shared/DetailItemEditCard.vue";
import RouteNames from "@/router/RouteNames";
import { getRequiredRules } from '@/shared/utils/inputRulesUtils.js';
import AutoCompleteWithSelectAll from "../../../components/shared/fields/AutoCompleteWithSelectAll.vue";

export default {
  name: "AddTeamMember",
  components: {
    DetailItemEditCard,
    AutoCompleteWithSelectAll
  },
  props: {
    teamId: {
      required: true,
      type: String
    },
  },
  data() {
    return {
      RouteNames,
      selectedMembers: [],
      members: [],
      autoCompletePromises: [this.fetchNoTeamUsers()],
      detailItemEditCardPromises: []
    }
  },
  methods: {
    getRequiredRules,
    usersAddedSuccessCallback(data) {
      this.$router.push({ name: RouteNames.TEAM_DETAIL, params: { teamId: data.id } })
    },
    fetchNoTeamUsers() {
      return this.$store.dispatch("teamsModule/fetchNoTeamUsers", this.teamId).then(result => this.members = result)
    }
  },
  computed: {
    getFormResultSet() {
      return { memberIds: this.selectedMembers }
    }
  },
}
</script>